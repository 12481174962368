import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";

import "./population-dashboard.css";

export const PopulationDashboard: React.FC = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/population-dashboard",
    });
  }, []);

  return (
    <div className="iframe-container">
      <Helmet>
        <html lang="en" />
        <title>Aralytiks &#8211; Kosovo Demographic Dashboard</title>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="The Kosovo Demographic Dashboard project is a comprehensive data analytics and reporting initiative by Aralytiks to provide valuable insights into population metrics and trends. Click to view the Kosovo Demographic Dashboard."
        />
        <meta property="og:title" content="Kosovo Demographic Dashboard" />
        <meta
          property="og:description"
          content="The Kosovo Demographic Dashboard project is a comprehensive data analytics and reporting initiative by Aralytiks to provide valuable insights into population metrics and trends. Click to view the Kosovo Demographic Dashboard."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://reports.aralytiks.com/population-dashboard"
        />
        <meta
          property="og:image"
          content="https://reports.aralytiks.com/population-dashboard.png"
        />
      </Helmet>
      <iframe
        title="Kosovo Demographic Dashboard"
        src="https://app.powerbi.com/view?r=eyJrIjoiNWM2NDkyMjUtMjkzYS00MmE1LTlkYjYtZjE4YWJiNGNiNTM0IiwidCI6IjkyNTBmZTJlLTdhOWEtNDBlYy1hMzA2LTQ5ZDY5ODdhODI3MiIsImMiOjl9"
        allowFullScreen={true}
      ></iframe>
    </div>
  );
};
