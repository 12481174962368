import React, { useEffect } from "react";
import { Box, Container, Typography, Paper, Link } from "@mui/material";
import { styled } from "@mui/system";
import ReactPlayer from "react-player";
import ReactGA from "react-ga4";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const VideoContainer = styled(Box)({
  width: "100%",
  backgroundColor: "#000",
  marginBottom: "16px",
  position: "relative",
  paddingTop: "56.25%", // 16:9 aspect ratio
});

const VideoWrapper = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
});

const DocumentContainer = styled(Paper)({
  width: "100%",
  padding: "16px",
  marginTop: "16px",
  display: "flex",
  alignItems: "center",
  boxSizing: "border-box",
});

const VideoPlayer = () => {
  return (
    <VideoContainer>
      <VideoWrapper>
        <ReactPlayer
          url="/videos/banking-report-presentation.mp4" // Local video file
          width="100%"
          height="100%"
          controls
          playing={true}
          muted={true} // Some browsers require muted for autoplay
        />
      </VideoWrapper>
    </VideoContainer>
  );
};

const DocumentSection = () => {
  return (
    <DocumentContainer>
      <PictureAsPdfIcon fontSize="large" />
      <Box ml={2}>
        <Typography variant="h6">Banking Report</Typography>
        <Typography variant="body1">
          <Link
            href="https://reports.aralytiks.com/documents/banking-report-presentation.pdf"
            target="_blank"
            rel="noopener"
          >
            Click here to view the document
          </Link>
        </Typography>
      </Box>
    </DocumentContainer>
  );
};

export const UserGuide = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/banking-dashboard/user-guide",
    });
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      style={{ backgroundColor: "#f0f0f0" }}
    >
      <Container
        maxWidth="md"
        style={{
          maxWidth: "900px",
          backgroundColor: "#fff",
          padding: "32px",
          borderRadius: "8px",
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Video - User Guide
        </Typography>
        <VideoPlayer />
        <DocumentSection />
      </Container>
    </Box>
  );
};
