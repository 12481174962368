import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { BankingDashboard } from "./pages/banking-dashboard";
import { PopulationDashboard } from "./pages/population-dashboard";
import { UserGuide } from "./pages/user-guide";
import { Home } from "./pages/home";

import "./App.css";

export const App: React.FC = () => {
  useEffect(() => {
    ReactGA.initialize("G-RDTF0Q46VJ");
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/kosovo-demographic-dashboard" element={<PopulationDashboard />} />
        <Route path="/banking-dashboard" element={<BankingDashboard />} />
        <Route path="/banking-dashboard/user-guide" element={<UserGuide />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
