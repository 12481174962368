import React from "react";
import { Container, Grid, Box } from "@mui/material";
import { ItemCard } from "./item-card";
import { styled } from "@mui/system";

const CenteredContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  backgroundColor: "rgb(240, 240, 240)",
});

const ContentWrapper = styled(Container)({
  maxWidth: "900px",
  padding: "32px",
  borderRadius: "8px",
});

export const Home: React.FC = () => {
  return (
    <CenteredContainer>
      <ContentWrapper>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <ItemCard
              id={1}
              title="Banking Dashboard"
              thumbnail="/banking-dashboard.png"
              url="/banking-dashboard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ItemCard
              id={2}
              title="Kosovo Demographic Dashboard"
              thumbnail="/population-dashboard.png"
              url="/kosovo-demographic-dashboard"
            />
          </Grid>
        </Grid>
      </ContentWrapper>
    </CenteredContainer>
  );
};
