import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";

import "./banking-dashboard.css";

export const BankingDashboard: React.FC = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/banking-dashboard",
    });
  }, []);

  return (
    <div className="iframe-container">
      <Helmet>
        <html lang="en" />
        <title>Aralytiks &#8211; Banking Dashboard</title>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="The Banking Dashboard project is a comprehensive data analytics and reporting initiative undertaken by Aralytiks to provide valuable insights into the banking sector of Kosovo. This project is the culmination of extensive research, data analysis, and collaboration between various stakeholders, aiming to offer a detailed overview of key banking metrics and trends."
        />
        <meta property="og:title" content="Banking Dashboard" />
        <meta
          property="og:description"
          content="The Banking Dashboard project is a comprehensive data analytics and reporting initiative undertaken by Aralytiks to provide valuable insights into the banking sector of Kosovo. This project is the culmination of extensive research, data analysis, and collaboration between various stakeholders, aiming to offer a detailed overview of key banking metrics and trends."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://reports.aralytiks.com/banking-dashboard"
        />
        <meta
          property="og:image"
          content="https://reports.aralytiks.com/banking-dashboard.png"
        />
      </Helmet>
      <iframe
        title="Banking Dashboard"
        src="https://app.powerbi.com/view?r=eyJrIjoiOGU4ZjRhYjAtZjYxZi00ZmI2LThjOTctZGJlMzAwZTk5MDVlIiwidCI6IjkyNTBmZTJlLTdhOWEtNDBlYy1hMzA2LTQ5ZDY5ODdhODI3MiIsImMiOjl9"
        allowFullScreen={true}
      ></iframe>
    </div>
  );
};
