import React, { useCallback } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";

const StyledCard = styled(Card)({
  maxWidth: "100%",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

interface ItemCardProps {
  id: number;
  title: string;
  thumbnail: string;
  url: string;
}

export const ItemCard: React.FC<ItemCardProps> = ({
  id,
  title,
  thumbnail,
  url,
}) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => navigate(url), [navigate, url]);

  return (
    <StyledCard>
      <CardActionArea onClick={handleClick}>
        <CardMedia component="img" height="300" image={thumbnail} alt={title} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </StyledCard>
  );
};
